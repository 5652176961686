body,p,
* {
  margin: 0;
  font-weight: 500;
  font-family: 'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    Roboto, 'Noto Sans', Ubuntu, 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


code {
  font-family: 'Poppins', source-code-pro, Menlo, Monaco, Consolas,
    'Courier New', monospace;
}
.scrolling-container {
  display: flex;
  white-space: nowrap;
  animation: scrolling 20s linear infinite;
}

@keyframes scrolling {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(-1 * 100% / 2));
  }
}

@tailwind base;
@tailwind components;
@tailwind utilities;