.google-map {
    padding-bottom: 50%; 
    position: relative;
    height: '600px';
}
.google-map iframe {
    left: 0;
    top: 0;
    position: absolute;
}
